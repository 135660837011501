import React from 'react';
import { useState } from "react";

import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";

import '../styles/styles.css';
import "react-image-lightbox/style.css"; 

import { PhotoResource } from "./usePhotos";

import usePhotos from "./usePhotos";

const GridGallery = () => {

  const { photos: pics } = usePhotos('gallery/');
  const [index, setIndex] = useState(-1);

  const currentImage = pics[index];
  const nextIndex = (index + 1) % pics.length;
  const nextImage = pics[nextIndex] || currentImage;
  const prevIndex = (index + pics.length - 1) % pics.length;
  const prevImage = pics[prevIndex] || currentImage;

  const handleClick = (index: number, item: PhotoResource) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex); 
  const handleMoveNext = () => setIndex(nextIndex); 

  return (
    <div>
      <Gallery 
        images={pics}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.original}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}

export default GridGallery;

import { Amplify, Auth } from "aws-amplify"
import awsconfig from "../aws-exports";
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';

import GridGallery from "./GridGallery";

import '@aws-amplify/ui-react/styles.css';
import '../styles/styles.css';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App({ }) {
  return (
    <div className="App">
      <div className="studio-name">BigSky Nature Photography</div>
      <GridGallery />
      {/* <Button class="logout-button" onClick={signOut}>Sign out</Button> */}
    </div>
  );
}

export default withAuthenticator(App);

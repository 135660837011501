import { Image } from "react-grid-gallery";
import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { S3ProviderListOutput, S3ProviderListOutputItem } from '@aws-amplify/storage';

export interface PhotoResource extends Image {
    key: string;
    original: string;
    origWidth: number;
    origHeight: number;
  }

const usePhotos = (folder: string) => {
  const [photos, setPhotos] = useState<Array<PhotoResource>>([]);

  const abortCtrl = new AbortController();

  useEffect(() => {
    Storage.list(folder, { level: 'public', pageSize: 'ALL' })
      .then(result => {
        const files: S3ProviderListOutputItem[] = processStorageList(result);
        const resources : Array<PhotoResource> = ([]);

        Promise.all(files.map(async (file) => {
          file.key && await getPhotoResource(file.key)
            .then(resource => {
              console.log('resource: ' + resource.key);
              resources.push(resource);
              // setPhotos(resources);
            })
        })).then(() => {
            // resources.forEach(photo => console.log(' <r> ' + photo.key));
            setPhotos(resources);
          });
      })
      .catch(err => {
        console.log(err);
        if (err.name === 'AbortError') {
          console.log('Storage list aborted!');
        } else {
          // setIsLoading(false);
          // setError(err.message);
        }
      });

      return () => abortCtrl.abort();
  }, [folder]);

  photos.sort((a, b) => (b.key || "").localeCompare(a.key || ""));

  return { photos };
}

async function getPhotoResource(key : string) : Promise<PhotoResource> {
  try {
    var thumbnailKey = key.split('/').join('/thumbnail.');
    const urlThumbnail = await Storage.get(thumbnailKey, { level: 'public', download: false, expires: 900 });
    const propsThumbnail = await Storage.getProperties(thumbnailKey);

    const urlOriginal = await Storage.get(key, { level: 'public', download: false, expires: 900 });
    // const propsOriginal = await Storage.getProperties(key);

    return {
      key: key,
      src: urlThumbnail,
      original: urlOriginal,
      width: +propsThumbnail.metadata.width,
      height: +propsThumbnail.metadata.height,
      origWidth: 0, //+propsOriginal.metadata.width,
      origHeight: 0, //+propsOriginal.metadata.height,
      caption: propsThumbnail.metadata.caption
    };
  }
  catch (error) {
    console.error(error);
    throw error;
  }
}

function processStorageList(response: S3ProviderListOutput) {
  let files : S3ProviderListOutputItem[] = [];
  let folders = new Set();
  response.results
  .filter((res: S3ProviderListOutputItem) => {
    // console.log('found file: ' + res.key);
    return res.key != null && !res.key.includes('thumbnail.');
  })
  .forEach((res: S3ProviderListOutputItem) => {
    if (res && res.key && res.size) {
      // console.log('keeping file: ' + res.key);
      files.push(res);
      // sometimes files declare a folder with a / within then
      let possibleFolder = res.key
        .split('/')
        .slice(0, -1)
        .join('/');
      if (possibleFolder) folders.add(possibleFolder + '/');
    } else {
      if (res.key) folders.add(res.key);
    }
  });

  console.log('processStorageList files=');
  files.forEach((item) => console.log('   src=' + item.key + ', size=' + item.size)); 
  return files;
}

export default usePhotos;